<template>
    <section style="margin-top: 9%;">
        <div class="card">
            <div class="table-responsive">
                <div class="table-responsive">
                <table class="table align-items-center table-flush">
                    <thead class="thead-light">
                    <tr class="text-left">
                        <th scope="col">ID</th>
                        <th scope="col">NAME</th>
                        <th scope="col">EMAIL</th>
                        <th scope="col">FORM TYPE</th>
                        <th scope="col">VIEW</th>
                    </tr>
                    </thead>
                    <tbody class="list" >
                    <tr class="text-left"
                        v-for="(wordpress_form_data,index) in wordpress_form_data.data"
                        :key="index"
                    >
                        <th >
                            {{ index + 1 }}
                        </th>
                        <th >
                            {{ wordpress_form_data['rider-name'] }}
                        </th>
                        <th >
                            {{ wordpress_form_data['rider-email'] }}
                        </th>

                        <th >
                            {{ wordpress_form_data['form-name'] }}
                        </th>

                        <th >
                            <button type="button" class="btn btn-sm btn-outline-success" @click="openModal(index)">
                                View
                            </button>
                        </th>


                    </tr>
                    </tbody>
                </table>
            </div>

                <div class="mx-auto">
                    <pagination v-model="page" :records="total"
                                :per-page="perPage" @paginate="getData"
                                theme="bootstrap4"/>
                </div>

            </div>

        </div>
        <button style="display: none" type="button" class="btn btn-sm btn-default" data-toggle="modal"
                ref="wordpressModal" data-target="#wordpressModal">
           Open Modal
        </button>
        <!-- Create Modal -->
        <div class="modal fade" id="wordpressModal" tabindex="-1"
             role="dialog" aria-labelledby="wordpressModalLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="createModalLabel">Data Detail</h5>
                        <button type="button" class="close" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                        <div class="modal-body">

                            <div class="form-group row">
                                <div class="col-sm-4" v-for="(item,i) in this.modalData" :key="i">
                                    <label  class="col-form-label">{{ i }}</label>
                                     <p>{{ item }}</p>
                                </div>

                            </div>
                        </div>

                </div>
            </div>
        </div>

    </section>

</template>

<script>
    import {mapGetters} from "vuex";
    import Pagination from "vue-pagination-2";
    export default {
        name: "WordpressDataList",
        data() {
            return {
                page: 1,
                modalData : {}
            }
        },
        computed : {
            ...mapGetters('wordpress_form_data', ['wordpress_form_data']),
            total() {
                if(!this.wordpress_form_data.total) return 0;
                return this.wordpress_form_data.total;
            },
            perPage() {
                if (!this.wordpress_form_data.per_page) return 0;
                return parseInt(this.wordpress_form_data.per_page);
            }
        },
        mounted() {
            this.$store.dispatch('event/event_wordpress_list',
                {id:this.$route.params.id,page: this.page});
        },
        components: {
            Pagination,
        },
        methods: {
            getData(page = 1) {
                this.page = page;
                this.$store.dispatch('event/event_wordpress_list',
                    {id:this.$route.params.id,page: this.page});
            },
            openModal(index) {
                this.modalData = this.wordpress_form_data.data[index]
                this.$refs.wordpressModal.click();
            }

        }
    }
</script>

<style scoped>
    .VuePagination__pagination {
        display: inline-flex;
    }
    .VuePagination {
        padding: 34px;
        text-align: center;
    }
    .VuePagination__count.VuePagination__count {
        color: #2d8968;
    }

</style>


